import Table from 'antd/es/table';
import CustomHeader from '../components/CustomHeader';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Popconfirm, Row, Skeleton, Spin } from 'antd';
import { TeacherType } from '../components/types';
import { toAddTeacher, toDeleteUser, toGetListOfTeachers } from '../Utils/API';
import { successMsg } from '../Utils/Utils';
import TeacherDetail from '../components/TeacherDetail';

const Enseignants = () => {
  const [form] = Form.useForm();
  const [toAdd, setToAdd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [data, setData] = useState<TeacherType[]>();
  const [selectedTeacher, setSelectedTeacher] = useState<any | null>(null);
  useEffect(() => {
    fetchData();
  }, [toAdd]);

  const columns: ColumnsType<TeacherType> = [
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
    },
    {
      title: 'Prénom',
      dataIndex: 'prenom',
    },
    {
      title: 'Club',
      dataIndex: 'club',
    },
    {
      title: 'Ecole',
      dataIndex: 'ecole',
    },
    {
      title: ' ',
      dataIndex: 'id',
      render: (id) => (
        <div onClick={(e) => e.stopPropagation()}>
          
          <Popconfirm
            title="Supression enseignant"
            description={`Voulez vous vraiment supprimer cet enseignant?`}
            okText="Oui"
            cancelText="Non"
            onConfirm={() => deleteEnseignant(id)}
          >
            <img className="jin--svg" src={require('../Styles/images/trash.svg').default} alt="delete" />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const fetchData = () => {
    toGetListOfTeachers().then((res: any[]) => {
      const list: TeacherType[] = [];
      res.forEach((teacher) => {
        list.push({
          nom: teacher.lastname,
          id: teacher.id,
          username: teacher.username,
          prenom: teacher.firstname,
          club: teacher.club ?? '-',
          ecole: teacher.ecole ?? '-',
          email: teacher.email,
          date: teacher.date,
        });
      });
      setData(list);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    });
  };

  const deleteEnseignant = (id: number): void => {
    toDeleteUser(id).then(() => {
      successMsg('Félécitations', `l'enseignant a été suprrimé avec succé`);
      fetchData();
    });
  };

  const addTeacher = (): void => {
    setSaving(true);
    form
      .validateFields()
      .then((values) => {
        toAddTeacher(values.firstname, values.lastname, values.email).then((res) => {
          successMsg('Félécitations', `l'enseignant ${values.firstname} ${values.lastname} a été ajouté avec succé`);
          fetchData()
        });
        setToAdd(false);
        
      })
      .catch((err) => {
        setSaving(false);
      });
  };
  return (
    <>
      <CustomHeader
        title="Enseignants"
        subTitle="Liste des Enseignants"
        button={!toAdd}
        textButton="Ajouter"
        onClick={() => {
          setToAdd(!toAdd);
          form.setFieldsValue({
            firstname: '',
            lastname: '',
            email: '',
          });
          setSaving(false);
        }}
      />
      {toAdd ? (
        <>
          <Row className="jin--add-container">
            <div style={{ margin: '4vh', width: '100%' }}>
              <span className="jin--subtitle-layout">Informations Enseignant</span>

              <Form form={form} layout="vertical" className="jin--form-add" name="basic">
                <Row gutter={32}>
                  <Col xl={12} lg={12} md={12} xs={24}>
                    <Form.Item
                      label="Nom"
                      name="lastname"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez saisir le nom de l'enseignant!",
                        },
                      ]}
                    >
                      <Input className="jin--input" />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} xs={24}>
                    <Form.Item
                      label="Prénom"
                      name="firstname"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez saisir le prenom de l'ensiegnant!",
                        },
                      ]}
                    >
                      <Input className="jin--input" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={32}>
                  {/* <Col xl={12} lg={12} md={12} xs={24}>
                    <Form.Item
                      label="École"
                      name="ecole"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Veuillez saisir le club!',
                      //   },
                      // ]}
                    >
                      <Input className="jin--input" />
                    </Form.Item>
                  </Col> */}
                  <Col xl={12} lg={12} md={12} xs={24}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          required: true,
                          message: "Veuillez vérifier l'email de l'ensiegnant!",
                        },
                      ]}
                    >
                      <Input className="jin--input" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Row>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Button className="jin--btn jin--btn-secondary" onClick={() => setToAdd(false)}>
              Annuler
            </Button>
            <Button onClick={() => addTeacher()} className="jin--btn jin--btn-primary" style={{ marginLeft: '2vw' }}>
              Enregistrer
              {saving && <Spin size="small" style={{ marginLeft: 10 }} />}
            </Button>
          </div>
        </>
      ) : loading ? (
        <Skeleton active />
      ) : (
        <>
        <Table className="jin--table" columns={columns} dataSource={data} bordered onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setSelectedTeacher(record);
                }, 
              };
            }} />
            <TeacherDetail
            teacherType={{
              teacher: selectedTeacher,
              setteacher: () => setSelectedTeacher(null),
            }}
          />
            </>
      )}
    </>
  );
};

export default Enseignants;
