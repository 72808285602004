import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Button, Col, Descriptions, Modal, Row } from 'antd';
import React, { FC, useEffect } from 'react';
import { CourseProps, TeacherProps } from './types';
import ReactPlayer from 'react-player';
import { successMsg } from '../Utils/Utils';
import { toResendActivationLink } from '../Utils/API';

// Create new plugin instance
const TeacherDetail: FC<TeacherProps> = ({ teacherType }) => {
  const resendActivationLink = (id: number) => {
    toResendActivationLink(id).then(() => {
      successMsg(
        'Informations',
        "Un email a été envoyée à l'enseignant sur l'addresse suivante: " + teacherType.teacher.email
      );
    });
  };
  return (
    <Modal
      className="jin--modal"
      onCancel={() => teacherType.setteacher()}
      open={teacherType.teacher != null ? true : false}
      style={{ minWidth: '90vh', top: 20 }}
      title={"Détail de l'enseignant"}
      footer={[]}
    >
      <Descriptions size="middle" layout="vertical" title="" column={2} bordered style={{ marginTop: '30px' }}>
        <Descriptions.Item span={1} label="Numéro" children={teacherType!.teacher?.id} />
        <Descriptions.Item span={1} label="Username" children={teacherType!.teacher?.username} />
        <Descriptions.Item span={1} label="Nom" children={teacherType!.teacher?.nom} />
        <Descriptions.Item span={1} label="Prénom" children={teacherType!.teacher?.prenom} />
        <Descriptions.Item span={1} label="Email" children={teacherType!.teacher?.email} />
        <Descriptions.Item span={1} label="Date de création" children={teacherType!.teacher?.date} />
        <Descriptions.Item span={1} label="Club" children={teacherType!.teacher?.club} />
        <Descriptions.Item span={1} label="Ecole" children={teacherType!.teacher?.ecole} />
      </Descriptions>
      <Row justify={'center'}>
        <Button
          onClick={() => resendActivationLink(teacherType.teacher.id)}
          className="jin--btn jin--btn-primary jin--btn-custom-header"
        >
          Envoyer l'email d'activation
        </Button>
      </Row>
    </Modal>
  );
};
export default TeacherDetail;
