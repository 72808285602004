import Table from 'antd/es/table';
import CustomHeader from '../components/CustomHeader';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Popconfirm, Row, Select, Skeleton } from 'antd';
import { AdherantType, ClubType, TeacherType } from '../components/types';
import { toAddClub, toDeleteClub, toGetClubList, toGetListOfStudents, toGetListOfTeachers } from '../Utils/API';
import { successMsg } from '../Utils/Utils';
import ClubDetail from '../components/ClubDetail';

const Clubs = () => {
  const [form] = Form.useForm();
  const [toAdd, setToAdd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [teachers, setTeachers] = useState<TeacherType[]>([]);
  const [students, setStudents] = useState<AdherantType[]>([]);
  const [clubs, setClubs] = useState<ClubType[]>([]);
  const [selectedClub, setSelectedClub] = useState<any | null>(null);

  const columns: ColumnsType<ClubType> = [
    {
      title: 'No',
      dataIndex: 'no',
    },
    {
      title: 'Responsable',
      dataIndex: 'responsableName',
      filterMode: 'tree',
      filterSearch: true,
    },
    {
      title: 'Club',
      dataIndex: 'name',
    },
    {
      title: 'Établissement',
      dataIndex: 'ecole',
    },
    {
      title: "Nb d'adhérants",
      dataIndex: 'nbAdherants',
    },
    {
      title: ' ',
      dataIndex: 'no',
      render: (id) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            title="Supression club"
            description={`Voulez vous vraiment supprimer ce club?`}
            okText="Oui"
            cancelText="Non"
            onConfirm={() => deleteClub(id)}
          >
            <img className="jin--svg" src={require('../Styles/images/trash.svg').default} alt="delete" />
          </Popconfirm>{' '}
        </div>
      ),
    },
  ];

  useEffect(() => {
    toGetListOfStudents().then((res: any[]) => {
      const list: AdherantType[] = [];
      res.forEach((student) => {
        list.push({
          id: student.id,
          username: student.username,
          adherant: student.lastname + ' ' + student.firstname,
          club: student.club,
          ecole: student.ecole,
        });
      });
      setStudents(list);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    });
    toGetListOfTeachers().then((res: any[]) => {
      const list: TeacherType[] = [];
      res.forEach((teacher) => {
        list.push({
          nom: teacher.firstname,
          id: teacher.id,
          username: teacher.username,
          prenom: teacher.lastname,
          club: '-',
          ecole: '-',
        });
      });
      setTeachers(list);
    });
    setTimeout(() => {
      setLoading(false);
    }, 200);
    getListOfClubs();
  }, []);

  const deleteClub = (id: number): void => {
    toDeleteClub(id).then(() => {
      successMsg('Félécitations', `le club a été suprrimé avec succé`);
      getListOfClubs();
    });
  };

  const getListOfClubs = () => {
    const list: ClubType[] = [];
    toGetClubList().then((clubs) => {
      clubs.forEach((club: any) => {
        const adherants: AdherantType[] = [];
        club.students.forEach((student: AdherantType) => {
          adherants.push({
            id: student.id,
            username: student.username,
            adherant: student.lastname + ' ' + student.firstname,
            nom: student.lastname,
            prenom: student.firstname,
            club: club.nom,
            ecole: club.ecole ?? '-',
            date: student.date,
            birthday: student.birthday,
          });
        });
        list.push({
          no: club.id,
          name: club.nom,
          ecole: club.ecole,
          responsableName: club.responsable.firstname + ' ' + club.responsable.lastname,
          responsable: {
            nom: club.responsable.firstname,
            id: club.responsable.id,
            username: club.responsable.username,
            prenom: club.responsable.lastname,
            club: club.name,
            ecole: club.ecole,
          },
          adherant: adherants,
          nbAdherants: adherants.length,
        });
      });
      setClubs(list);
    });
  };

  const addClub = () => {
    form.validateFields().then((values) => {
      toAddClub(values);
      setToAdd(false);
      successMsg('Félécitations', `le club a été ajouté avec succé`);
      getListOfClubs();
    });
  };

  return (
    <>
      <CustomHeader
        title="Clubs"
        subTitle="Liste des clubs"
        button={!toAdd}
        textButton="Ajouter"
        onClick={() => setToAdd(!toAdd)}
      />
      {toAdd ? (
        <>
          <Row className="jin--add-container">
            <div style={{ margin: '4vh', width: '100%' }}>
              <span className="jin--subtitle-layout">Informations Club</span>

              <Form form={form} layout="vertical" className="jin--form-add" name="basic">
                <Row gutter={32}>
                  <Col xl={12} lg={12} md={12} xs={24}>
                    <Form.Item
                      label="Nom"
                      name="nom"
                      rules={[
                        {
                          required: true,
                          message: 'Veuillez saisir le nom du club!',
                        },
                      ]}
                    >
                      <Input className="jin--input" />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} xs={24}>
                    <Form.Item
                      label="Établissement"
                      name="ecole"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez saisir le nom de l'établissement!",
                        },
                      ]}
                    >
                      <Input className="jin--input" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Responsable"
                      name="enseignantId"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez séléctionner le nom de l'ensiegnant!",
                        },
                      ]}
                    >
                      <Select className="jin--select">
                        {teachers.map((teacher) => (
                          <Select.Option value={teacher.id}>{teacher.prenom + ' ' + teacher.nom}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Adhérents"
                      name="adherantIds"
                      rules={[
                        {
                          required: true,
                          message: 'Veuillez séléctionner les adhéants!',
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        className="jin--select"
                        placeholder="Choisir la liste des adhérants"
                      >
                        {students.map((student) => (
                          <Select.Option value={student.id}>{student.adherant}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Row>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Button className="jin--btn jin--btn-secondary" onClick={() => setToAdd(false)}>
              Annuler{' '}
            </Button>
            <Button className="jin--btn jin--btn-primary" style={{ marginLeft: '2vw' }} onClick={() => addClub()}>
              Enregistrer{' '}
            </Button>
          </div>
        </>
      ) : loading ? (
        <Skeleton active />
      ) : (
        <>
          <Table
            className="jin--table"
            columns={columns}
            dataSource={clubs}
            bordered
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setSelectedClub(record);
                },
              };
            }}
          />
          <ClubDetail
            clubType={{
              club: selectedClub,
              setClub: () => setSelectedClub(null),
            }}
          />
        </>
      )}
    </>
  );
};

export default Clubs;
