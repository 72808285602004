import Table from 'antd/es/table';
import CustomHeader from '../components/CustomHeader';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import {
  Button,
  message,
  Upload,
  Col,
  Form,
  Input,
  Row,
  Skeleton,
  Switch,
  UploadFile,
  Space,
  Select,
  Popconfirm,
} from 'antd';
import { CourType } from '../components/types';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { toActivateOrDecativateMedia, toAddMedia, toDeleteMedia, toGetListOfMedia } from '../Utils/API';
import CourseViewer from '../components/CourseViewer';
import { successMsg } from '../Utils/Utils';

const Cours = () => {
  const [active, setActive] = useState(1);
  const [toAdd, setToAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<any[]>();
  const [file, setFile] = useState<File | string>('');
  const [selectedCourse, setSelectedCourse] = useState<any | null>(null);
  const [form] = Form.useForm();

  const columns: ColumnsType<CourType> = [
    {
      title: 'No',
      dataIndex: 'id',
    },
    {
      title: 'Nom',
      dataIndex: 'name',
      render: (name) => (
        <span style={{ cursor: 'pointer' }} onClick={() => setSelectedCourse(name)}>
          {name}
        </span>
      ),
    },
    {
      title: "Tranche d'age",
      dataIndex: 'tranche',
      render: (tranche) => <span>{tranche == 0 ? 'de 11 ans à 17 ans' : 'de 18 ans à 24 ans'}</span>,
    },
    {
      title: 'Responsable',
      dataIndex: 'createdBy',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: ' ',
      dataIndex: 'id',
      render: (id) => (
        <div onClick={(e) => e.stopPropagation()}>
          {' '}
          <Switch
            onClick={(e) => changeMediaStatus(id)}
            className="jin--switch"
            defaultChecked={getStatus(id)}
            style={{ marginRight: '1vh' }}
          />
          <Popconfirm
            title="Supression cours"
            description={`Voulez vous vraiment supprimer ce cours?`}
            okText="Oui"
            cancelText="Non"
            onConfirm={() => deleteMedia(id)}
          >
            <img className="jin--svg" src={require('../Styles/images/trash.svg').default} alt="delete" />
          </Popconfirm>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getList();
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []);

  const getStatus = (id: number) => {
    return list?.filter((el) => el.id === id)[0].actif;
  };

  const changeMediaStatus = (id: number) => {
    toActivateOrDecativateMedia(id).then(() => {
      successMsg('Félicitations', 'Modifications effectués avec succés');
    });
  };

  const deleteMedia = (id: number) => {
    toDeleteMedia(id).then(() => {
      getList();

      successMsg('Félicitations', 'Modifications effectués avec succés');
    });
  };
  const props: UploadProps = {
    name: 'file',
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },

    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const getList = () => {
    toGetListOfMedia().then((res) => {
      setList(res);
    });
  };

  interface uploadedType {
    title: string;
    tranche: string;
    text: string;
    fileToSend: File | string;
  }
  const addMedia = () => {
    form.validateFields().then((res) => {
      let data: uploadedType = {
        title: res.titre,
        tranche: res.age,
        text: '',
        fileToSend: '',
      };

      if (active === 1) {
        data = { ...data, text: res.status };
      } else {
        data.fileToSend = file;
      }
      var bodyFormData = new FormData();
      if (active === 1) {
        bodyFormData.append('text', res.status);
      } else {
        bodyFormData.append('file', file);
      }

      bodyFormData.append('title', res.titre);
      bodyFormData.append('tranche', res.age);
      bodyFormData.append('description', res.status);
      toAddMedia(bodyFormData).then((res) => {
        successMsg('Félécitations', `le cour a été ajouté avec succé`);
        getList();
        setToAdd(false);
      });
    });
  };

  return (
    <>
      <CustomHeader
        title="Cours"
        subTitle="Liste des Cours"
        button={!toAdd}
        textButton="Publier"
        onClick={() => setToAdd(!toAdd)}
      />
      {toAdd ? (
        <>
          <Row className="jin--add-container">
            <Row className="jin--tab-container">
              <Col span="1" />
              <Col span="11" onClick={() => setActive(1)} className={active === 1 ? 'jin--tab-active' : 'jin--tab'}>
                <span className="jin--tab-item ">Texte</span>
              </Col>
              <Col span="1" style={{ color: '#D9D9D9' }}>
                |
              </Col>

              <Col span="11" onClick={() => setActive(2)} className={active === 2 ? 'jin--tab-active' : 'jin--tab'}>
                <span className="jin--tab-item">Video/Fichier</span>
              </Col>
              <Col span="1" />
            </Row>
            <div style={{ margin: '0 4vh 0 4vh', width: '100%' }}>
              <Form form={form} layout="vertical" className="jin--form-add" name="basic">
                {active === 1 ? (
                  <>
                    <Row gutter={32}>
                      <Col xl={12} lg={12} md={12} xs={24}>
                        <Form.Item
                          label="Titre"
                          name="titre"
                          rules={[
                            {
                              required: true,
                              message: 'Veuillez saisir le titre de la vidéo!',
                            },
                          ]}
                        >
                          <Input className="jin--input" />
                        </Form.Item>
                      </Col>
                      <Col xl={12} lg={12} md={12} xs={24}>
                        <Form.Item
                          label="Tranche d'age"
                          name="age"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez saisir la tranche d'age!",
                            },
                          ]}
                        >
                          <Select
                            className="jin--select"
                            onChange={(e) => {
                              console.log(e);
                              form.setFieldValue('age', e);
                            }}
                          >
                            <Select.Option value={0}>11 - 17</Select.Option>
                            <Select.Option value={1}>18 - 24</Select.Option>
                          </Select>{' '}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Status"
                          name="status"
                          rules={[
                            {
                              required: true,
                              message: 'Veuillez saisir le texte!',
                            },
                          ]}
                        >
                          <Input.TextArea rows={6} className="jin--input-texterea" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row gutter={32}>
                      <Col xl={12} lg={12} md={12} xs={24}>
                        <Form.Item
                          label="Titre"
                          name="titre"
                          rules={[
                            {
                              required: true,
                              message: 'Veuillez saisir le titre de la vidéo!',
                            },
                          ]}
                        >
                          <Input className="jin--input" />
                        </Form.Item>
                      </Col>
                      <Col xl={12} lg={12} md={12} xs={24}>
                        <Form.Item
                          label="Tranche d'age"
                          name="age"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez saisir la tranche d'age!",
                            },
                          ]}
                        >
                          <Select className="jin--select" onChange={(e) => form.setFieldValue('age', e)}>
                            <Select.Option value={0}>11 - 17</Select.Option>
                            <Select.Option value={1}>18 - 24</Select.Option>
                          </Select>{' '}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Description" name="status">
                          <Input.TextArea className="jin--input-texterea" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Space direction="vertical" style={{ width: '100%', marginBottom: '20px' }} size="large">
                      <Row>
                        <Col span={12}>
                          <Upload maxCount={1} {...props}>
                            <Button className="jin--btn jin--btn-primary" icon={<UploadOutlined />}>
                              Cliquer pour ajouter un fichier
                            </Button>
                          </Upload>
                        </Col>
                      </Row>
                    </Space>
                  </>
                )}
              </Form>
            </div>
          </Row>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Button className="jin--btn jin--btn-secondary" onClick={() => setToAdd(false)}>
              Annuler{' '}
            </Button>
            <Button className="jin--btn jin--btn-primary" style={{ marginLeft: '2vw' }} onClick={() => addMedia()}>
              Enregistrer{' '}
            </Button>
          </div>
        </>
      ) : loading ? (
        <Skeleton active />
      ) : (
        <>
          <Table
            className="jin--table"
            columns={columns}
            dataSource={list}
            bordered
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setSelectedCourse(record);
                }, // click row
              };
            }}
          />
          <CourseViewer
            courseType={{
              course: selectedCourse,
              setCourse: () => setSelectedCourse(null),
            }}
          />
        </>
      )}
    </>
  );
};

export default Cours;
