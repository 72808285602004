import Table, { TableProps } from 'antd/es/table';
import CustomHeader from '../components/CustomHeader';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { Col, Row, Skeleton, Form, Spin, Button, Input, DatePicker, Popconfirm, Modal, Select } from 'antd';
import { AdherantType, ClubType } from '../components/types';
import { toAddStudent, toDeleteUser, toGetClubList, toGetListOfStudents, toUpdateClub } from '../Utils/API';
import locale from 'antd/es/date-picker/locale/fr_FR';
import 'dayjs/locale/fr';
import { errorMsg, successMsg } from '../Utils/Utils';
import StudentDetail from '../components/StudentDetail';

const Adherants = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<AdherantType[]>();
  const [toAdd, setToAdd] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [birthday, setBirthday] = useState<string>('');
  const [selectedAdherant, setSelectedAdherant] = useState<any | null>(null);
  const [id, setId] = useState<number>(-1);
  const [clubId, setClubId] = useState<number>(-1);
  const [clubs, setClubs] = useState<ClubType[]>([]);

  const onChange: TableProps<AdherantType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const formatFilters = (): any[] => {
    const res: any[] = [];
    data?.forEach((student) => {
      res.push({
        text: student.adherant,
        value: student.adherant,
      });
    });

    return res;
  };

  const columns: ColumnsType<AdherantType> = [
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Adhérant',
      dataIndex: 'adherant',
      filterMode: 'tree',
      filterSearch: true,
      filters: formatFilters(),
      onFilter: (value: string | number | boolean, record: AdherantType) => record.adherant.includes(value + ''),
    },
    {
      title: 'Club',
      dataIndex: 'club',
    },
    {
      title: 'Établissement',
      dataIndex: 'ecole',
    },
    {
      title: ' ',
      dataIndex: 'id',
      render: (id) => (
        <div onClick={(e) => e.stopPropagation()}>
          <img
            className="jin--svg"
            src={require('../Styles/images/details.svg').default}
            alt="modifier club"
            style={{ marginRight: '10px' }}
            onClick={() => setId(id)}
          />
          <Popconfirm
            title="Supression adhérants"
            description={`Voulez vous vraiment supprimer cet adhérant?`}
            okText="Oui"
            cancelText="Non"
            onConfirm={() => deleteStudent(id)}
          >
            <img className="jin--svg" src={require('../Styles/images/trash.svg').default} alt="delete" />
          </Popconfirm>
        </div>
      ),
    },
  ];
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    toGetListOfStudents().then((res: any[]) => {
      const list: AdherantType[] = [];
      res.forEach((student) => {
        list.push({
          id: student.id,
          username: student.username,
          adherant: student.lastname + ' ' + student.firstname,
          nom: student.lastname,
          prenom: student.firstname,
          club: student.club ?? '-',
          ecole: student.ecole ?? '-',
          date: student.date,
          birthday: student.birthday,
        });
      });
      setData(list);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    });
    getListOfClubs();
  };

  const deleteStudent = (id: number): void => {
    toDeleteUser(id).then(() => {
      successMsg('Félécitations', `l'adhérant a été suprrimé avec succé`);
      fetchData();
    });
  };

  const addStudent = () => {
    form
      .validateFields()
      .then((values) => {
        toAddStudent(values.firstname, values.lastname, birthday).then((res) => {
          successMsg('Félécitations', `l'adhérant a été ajouté avec succé`);
          setToAdd(false);
          fetchData();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListOfClubs = () => {
    const list: ClubType[] = [];
    toGetClubList().then((clubsList) => {
      clubsList.forEach((club: any) => {
        const adherants: AdherantType[] = [];
        club.students.forEach((student: AdherantType) => {
          adherants.push({
            id: student.id,
            username: student.username,
            adherant: student.lastname + ' ' + student.firstname,
            nom: student.lastname,
            prenom: student.firstname,
            club: club.nom,
            ecole: club.ecole ?? '-',
            date: student.date,
            birthday: student.birthday,
          });
        });
        list.push({
          no: club.id,
          name: club.nom,
          ecole: club.ecole,
          responsableName: club.responsable.firstname + ' ' + club.responsable.lastname,
          responsable: {
            nom: club.responsable.firstname,
            id: club.responsable.id,
            username: club.responsable.username,
            prenom: club.responsable.lastname,
            club: club.name,
            ecole: club.ecole,
          },
          adherant: adherants,
          nbAdherants: adherants.length,
        });
      });
      setClubs(list);
    });
  };

  const updateClub = () => {
    if (clubId === -1 || id === -1) {
      errorMsg('Erreur', 'Merci de vérifier vos informations');
    }

    toUpdateClub(id, clubId).then(() => {
      successMsg('Féléctiations', 'Modifications effectués avec succé');
      setClubId(-1);
      setId(-1);
      fetchData();
    });
  };

  return (
    <>
      <CustomHeader
        button={!toAdd}
        title="Adhérants"
        subTitle="Liste des adhérants"
        textButton="Ajouter"
        onClick={() => {
          setToAdd(!toAdd);
          form.setFieldsValue({
            firstname: '',
            lastname: '',
            birthday: '',
          });
          setSaving(false);
        }}
      />
      {toAdd ? (
        <>
          <Row className="jin--add-container">
            <div style={{ margin: '4vh', width: '100%' }}>
              <span className="jin--subtitle-layout">Informations Adhérant</span>

              <Form form={form} layout="vertical" className="jin--form-add" name="basic">
                <Row gutter={32}>
                  <Col xl={12} lg={12} md={12} xs={24}>
                    <Form.Item
                      label="Nom"
                      name="lastname"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez saisir le nom de l'adhérant!",
                        },
                      ]}
                    >
                      <Input className="jin--input" />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} xs={24}>
                    <Form.Item
                      label="Prénom"
                      name="firstname"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez saisir le prenom de l'adhérant!",
                        },
                      ]}
                    >
                      <Input className="jin--input" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col xl={12} lg={12} md={12} xs={24}>
                    <Form.Item
                      label="Date de naissance"
                      name="birthday"
                      rules={[
                        {
                          type: 'date',
                          required: true,
                          message: 'Veuillez vérifeir la date de naissance!',
                        },
                      ]}
                    >
                      <DatePicker onChange={(v, date) => setBirthday(date)} locale={locale} className="jin--input" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Row>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Button className="jin--btn jin--btn-secondary" onClick={() => setToAdd(false)}>
              Annuler
            </Button>
            <Button className="jin--btn jin--btn-primary" onClick={() => addStudent()} style={{ marginLeft: '2vw' }}>
              Enregistrer
              {saving && <Spin size="small" style={{ marginLeft: 10 }} />}
            </Button>
          </div>
        </>
      ) : loading ? (
        <Skeleton active />
      ) : (
        <>
          <Table
            className="jin--table"
            columns={columns}
            dataSource={data}
            bordered
            onChange={onChange}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setSelectedAdherant(record);
                },
              };
            }}
          />
          <StudentDetail
            studentType={{
              student: selectedAdherant,
              setStudent: () => setSelectedAdherant(null),
            }}
          />
          <Modal
            className="jin--modal"
            title="Modifier club"
            open={id !== -1}
            onCancel={() => setId(-1)}
            onOk={() => updateClub()}
          >
            <Select style={{ width: '100%' }} className="jin--select" onChange={(e) => setClubId(e)}>
              {clubs.map((el) => (
                <Select.Option value={el.no}> {el.name}</Select.Option>
              ))}
            </Select>
          </Modal>
        </>
      )}
    </>
  );
};

export default Adherants;
