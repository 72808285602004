

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {  Descriptions, Modal, Table } from 'antd';
import { FC, useState } from 'react';
import { AdherantType, ClubProps } from './types';
import StudentDetail from './StudentDetail';
import { ColumnsType } from 'antd/es/table';

// Create new plugin instance
const ClubDetail: FC<ClubProps> = ({ clubType }) => {
    const [selectedAdherant, setSelectedAdherant] = useState<any | null>(null);
const columns: ColumnsType<AdherantType> = [
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Adhérant',
      dataIndex: 'adherant',
    },
    
  ];
  return (
    <Modal
      className="jin--modal"
      onCancel={() => clubType.setClub()}
      open={clubType.club != null ? true : false}
      
      style={{ minWidth: '90vh', top: 20 }}
      title={"Détail du club"}
      footer={[]}
    >
      <Descriptions size="middle" layout="vertical" title="" column={2} bordered style={{ marginTop: '30px' }}>
        <Descriptions.Item span={1} label="Numéro" children={clubType!.club?.no} />
        <Descriptions.Item span={1} label="Nom" children={clubType!.club?.name} />
        <Descriptions.Item span={1} label="Ecole" children={clubType!.club?.ecole} />
        <Descriptions.Item span={1} label="Responsable" children={clubType!.club?.responsableName} />
        <Descriptions.Item span={1} label="Nombre d'afhérants" children={clubType!.club?.nbAdherants} />        
        
      </Descriptions>
      
<Table className="jin--table" columns={columns} dataSource={clubType!.club?.adherant} bordered  
          onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setSelectedAdherant(record);
                }, 
              };
            }}/>
          <StudentDetail
          studentType={{
              student: selectedAdherant,
              setStudent: () => setSelectedAdherant(null),
            }}

          />
    </Modal>
  );
};
export default ClubDetail;
