import axios from 'axios';
import cookie from 'react-cookies';
import CAxios from './CAxios';

export const toLogin = (username: string, password: string) => {
  return axios
    .post(process.env.REACT_APP_API_URL + '/authenticate', {
      username,
      password,
    })

    .then(function (response) {
      if (response.data.success.active === 0) {
        return 0;
      }
      cookie.save('token', response.data.success.token, { path: '/' });
      cookie.save('type', response.data.success.type, { path: '/' });
      cookie.save('username', response.data.success.username, { path: '/' });
      cookie.save('nomComplet', response.data.success.nomComplet, { path: '/' });
      cookie.save('id', response.data.success.id, { path: '/' });
      return 1;
    })
    .catch(function (error) {
      return -1;
    });
};

export const toGetListOfTeachers = async () => {
  const { data } = await CAxios.post('/api/teacher/list');
  return data.success.users ?? [];
};

export const toGetListOfMedia = async () => {
  const { data } = await CAxios.post('/api/media/list');
  return data.success.data ?? [];
};

export const toGetListOfStudents = async () => {
  const { data } = await CAxios.post('/api/student/list');
  return data.success.users ?? [];
};

export const toAddTeacher = async (firstname: string, lastname: string, email: string) => {
  const { data } = await CAxios.post(`/api/user/add`, { firstname, lastname, email, type: 1 });
  return data.success && data.success.data ? data.success.data : [];
};

export const toDeleteUser = async (id: number) => {
  const { data } = await CAxios.post(`/api/user/delete`, { id });
  return data.success && data.success.data ? data.success.data : [];
};

export const toAddStudent = async (firstname: string, lastname: string, birthday: string) => {
  const { data } = await CAxios.post(`/api/user/add`, { firstname, lastname, birthday, type: 2 });
  return data.success && data.success.data ? data.success.data : null;
};

export const toAddClub = async (values: any) => {
  const { data } = await CAxios.post(`/api/club/add`, values);
  return data.success && data.success.data ? data.success.data : null;
};

export const toAddMedia = async (values: any) => {
  const { data } = await CAxios.post(`/api/media/add`, values);

  return data.success && data.success.file ? data.success.data : null;
};

export const toGetClubList = async () => {
  const { data } = await CAxios.post(`/api/club/list`);
  return data.success && data.success.data ? data.success.data : null;
};

export const toDeleteClub = async (id: number) => {
  const { data } = await CAxios.post(`/api/club/delete`, { id });
  return data.success && data.success.data ? data.success.data : [];
};

export const toUpdatePassword = async (password: string, key: string) => {
  const { data } = await CAxios.post(`/password/update`, { password, key });
  return data.success && data.success.data ? data.success.data : null;
};

export const toResendActivationLink = async (id: number) => {
  const { data } = await CAxios.post(`/api/send/link`, { id });
  return data.success && data.success.data ? data.success.data : null;
};

export const toResendActivationLinkEmail = async (email: string) => {
  const { data } = await CAxios.post(`/send/link/email`, { email });
  return data.success && data.success.data ? data.success.data : null;
};

export const toGetData = async () => {
  const { data } = await CAxios.post(`/api/data/get`);
  return data.success && data.success ? data.success : null;
};

export const toGetStat = async () => {
  const { data } = await CAxios.post(`/api/stat`);
  return data.success && data.success.data ? data.success.data : [];
};

export const toActivateOrDecativateMedia = async (id: number) => {
  const { data } = await CAxios.post(`/api/media/change`, { id });
  return data.success && data.success.data ? data.success.data : null;
};

export const toDeleteMedia = async (id: number) => {
  const { data } = await CAxios.post(`/api/media/delete`, { id });
  return data.success && data.success.data ? data.success.data : null;
};

export const toUpdateClub = async (id: number, clubId: number) => {
  const { data } = await CAxios.post(`/api/club/update`, { id, clubId });
  return data.success && data.success.data ? data.success.data : null;
};
