import { notification } from 'antd';

export const errorMsg = (msg: string, description: string | undefined) => {
  notification.error({
    message: msg,
    description,
    placement: 'bottom',
  });
};

export const successMsg = (msg: string, description: string | undefined) => {
  notification.success({
    message: msg,
    description,
    placement: 'bottom',
  });
};
