import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Col, Descriptions, Modal, Row } from 'antd';
import React, { FC, useEffect } from 'react';
import { CourseProps } from './types';
import ReactPlayer from 'react-player';

// Create new plugin instance
const CourseViewer: FC<CourseProps> = ({ courseType }) => {
  useEffect(() => {
    console.log(courseType);
  }, []);
  return (
    <Modal
      className="jin--modal"
      onCancel={() => courseType.setCourse()}
      open={courseType.course != null ? true : false}
      style={{ minWidth: '90vh', top: 20 }}
      title={courseType!.course?.name ?? ''}
      footer={[]}
    >
      <Descriptions size="middle" layout="vertical" title="" column={2} bordered style={{ marginTop: '30px' }}>
        <Descriptions.Item span={1} label="Numéro" children={courseType!.course?.id} />
        <Descriptions.Item span={1} label="Nom" children={courseType!.course?.name} />
        <Descriptions.Item
          span={1}
          label="Tranche d'age"
          children={courseType!.course?.name === 0 ? 'de 13 ans à 17 ans' : 'de 18 ans à 24 ans'}
        />
        <Descriptions.Item span={1} label="Description" children={courseType!.course?.description} />
        <Descriptions.Item span={1} label="Responsable(crée par)" children={courseType!.course?.createdBy} />
        <Descriptions.Item span={1} label="Type" children={courseType!.course?.type} />
        <Descriptions.Item span={1} label="Date de création" children={courseType!.course?.dtCreated} />
        <Descriptions.Item span={1} label="Actif" children={courseType!.course?.actif ? 'oui' : 'non'} />
      </Descriptions>
      {!courseType!.course?.type.includes('text') &&(
      <Row justify={'center'} style={{ marginTop: '50px' }} gutter={32}>
        <Col span="2" />
        <Col span="20">
          {courseType!.course?.type.includes('pdf') ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer fileUrl={process.env.REACT_APP_API_URL+"/media/"+courseType!.course?.fileName} />
            </Worker>
          ) : (
            <ReactPlayer controls={true} playing url={process.env.REACT_APP_API_URL+"/media/"+courseType!.course?.fileName} />
          )}
        </Col>
        <Col span="2" />
      </Row>)}
    </Modal>
  );
};
export default CourseViewer;
