

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Button, Descriptions, Modal, Row } from 'antd';
import { FC } from 'react';
import { successMsg } from '../Utils/Utils';
import { StudentProps } from './types';

// Create new plugin instance
const StudentDetail: FC<StudentProps> = ({ studentType }) => {
  
  return (
    <Modal
      className="jin--modal"
      onCancel={() => studentType.setStudent()}
      open={studentType.student != null ? true : false}
      
      style={{ minWidth: '90vh', top: 20 }}
      title={"Détail de l'adhérant"}
      footer={[]}
    >
      <Descriptions size="middle" layout="vertical" title="" column={2} bordered style={{ marginTop: '30px' }}>
        <Descriptions.Item span={1} label="Numéro" children={studentType!.student?.id} />
        <Descriptions.Item span={1} label="Username" children={studentType!.student?.username} />
        <Descriptions.Item span={1} label="Nom" children={studentType!.student?.nom} />
        <Descriptions.Item span={1} label="Prénom" children={studentType!.student?.prenom} />        
        <Descriptions.Item span={1} label="Date de naissance" children={studentType!.student?.birthday} />        
        <Descriptions.Item span={1} label="Date de création" children={studentType!.student?.date} />
        <Descriptions.Item span={1} label="Club" children={studentType!.student?.club} />
        <Descriptions.Item span={1} label="Ecole" children={studentType!.student?.ecole} />
      </Descriptions>

    </Modal>
  );
};
export default StudentDetail;
