import React, { useState } from 'react';
import { Col, MenuProps, Row } from 'antd';
import { Layout, Menu, theme } from 'antd';
import Clubs from '../pages/Clubs';
import Adherants from '../pages/Adherants';
import Enseignants from '../pages/Ensignants';
import Cours from '../pages/Cours';
import Notifications from '../pages/Notifications';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import Accueil from '../pages/Accueil';
import About from '../pages/About';

const { Header, Content, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const LayoutMenu: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [displayableItem, setDisplayableItem] = useState(<Accueil />);
  const navigate = useNavigate();
  const [type, setType] = useState<number>(cookie.load('type'));
  const items: MenuItem[] = [
    getItem('Accueil', '1'),
    getItem('Enseignants', '4'),
    getItem('Adhérants', '3'),
    getItem('Clubs', '2'),
    getItem('Cours', '5'),
    //getItem('Notifications', '6'),
    getItem('A propos', '7'),
  ];

  const itemsEnseignants: MenuItem[] = [
    getItem('Accueil', '1'),
    getItem('Adhérants', '3'),
    getItem('Cours', '5'),
    getItem('A propos', '7'),
  ];
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const updateRender = (index: string) => {
    let item = <Accueil />;
    switch (index) {
      case '1':
        item = <Accueil />;
        break;
      case '2':
        item = <Clubs />;
        break;
      case '3':
        item = <Adherants />;
        break;
      case '4':
        item = <Enseignants />;
        break;
      case '5':
        item = <Cours />;
        break;
      case '6':
        item = <Notifications />;
        break;
      case '7':
        item = <About />;
        break;
      default:
        break;
    }
    setDisplayableItem(item);
  };

  const logout = () => {
    cookie.remove('token', { path: '/' });
    cookie.remove('type', { path: '/' });
    cookie.remove('id', { path: '/' });
    cookie.remove('nomComplet', { path: '/' });

    navigate('/login');
  };
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="jin--logo-container">
          <img
            className="jin--logo-menu-left"
            src={require('../Styles/images/icon_white.svg').default}
            alt="j'innove"
          />
        </div>
        <div className="jin--admin-container">
          <span className="jin--text-admin">{type == 0 ? "J'innove Admin" : "J'innove Enseignant"}</span>
        </div>
        <Menu
          style={{ marginTop: '4vh' }}
          theme="dark"
          defaultSelectedKeys={['1']}
          onSelect={(item) => updateRender(item.key)}
          mode="inline"
          items={type == 0 ? items : itemsEnseignants}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Row justify="center" style={{ paddingTop: '10px' }}>
            <Col span="5">
              <img
                className="jin--header-img"
                style={{ marginLeft: '10px' }}
                alt=""
                src={require('../Styles/images/fidel.jpg')}
              />
            </Col>
            <Col span="5" style={{ paddingTop: '10px' }}>
              <img className="jin--header-img" alt="" src={require('../Styles/images/innovi.png')} />
            </Col>
            <Col span="5">
              <img className="jin--header-img" alt="" src={require('../Styles/images/expertise.png')} />
            </Col>
            <Col span="5">
              <img className="jin--header-img" alt="" src={require('../Styles/images/ue.jpg')} />
            </Col>
            <Col span="4" style={{ float: 'right', marginTop: '20px' }}>
              <img
                style={{ marginRight: '1vw', cursor: 'pointer' }}
                src={require('../Styles/images/notif.png')}
                alt="j'innove"
              />
              <img
                style={{ marginRight: '2vw', cursor: 'pointer' }}
                onClick={() => logout()}
                src={require('../Styles/images/logout.png')}
                alt="déconnexion"
              />
            </Col>
          </Row>
          <div style={{ float: 'right', marginTop: '2vh', marginRight: '2vw' }}>
            <img
              style={{ marginRight: '1vw', cursor: 'pointer' }}
              src={require('../Styles/images/notif.png')}
              alt="j'innove"
            />
            <img
              style={{ marginRight: '2vw', cursor: 'pointer' }}
              onClick={() => logout()}
              src={require('../Styles/images/logout.png')}
              alt="déconnexion"
            />
          </div>
        </Header>
        <Content>
          <div style={{ minHeight: '80vh', margin: '2px 0', background: colorBgContainer }}>{displayableItem}</div>
        </Content>
        <Layout.Footer>
          <Row>
            <Col span="8">
              <span className="jin--text">By Fidel Metouia</span>
            </Col>
            <Col span="8">
              <span className="jin--text">
                <span style={{ color: '#6B6B6B' }}>Email: </span>
                <a href="mailto:fidelmetouia@gmail.com">fidelmetouia@gmail.com</a>
              </span>
            </Col>
            <Col span="8">
              <span className="jin--text">
                <span style={{ color: '#6B6B6B' }}>Téléphone:</span> 93 89 81 95
              </span>
            </Col>
          </Row>
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutMenu;
