import { Button, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface HeaderItems {
  title: string;
  subTitle: string;
  button?: boolean;
  textButton?: string;
  onClick?: () => void;
}
const CustomHeader = (headerItems: HeaderItems) => {
  const { title, subTitle, button, textButton, onClick } = headerItems;
  return (
    <Row className="jin--custom-header">
      <Col span="18">
        <p className="jin--title-layout">{title}</p>
        <p className="jin--subtitle-layout">{subTitle}</p>
      </Col>
      <Col span="6" style={{ float: 'right' }}>
        {button && (
          <Button onClick={onClick} className="jin--btn jin--btn-primary jin--btn-custom-header">
            <Row>
              <Col span="4">
                <PlusOutlined span={16} />
              </Col>
              <Col span="18">
                <span className="jin--font">{textButton}</span>
              </Col>
            </Row>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default CustomHeader;
