import { Card, Col, Row, Skeleton, Space } from 'antd';
import { PureComponent, useEffect, useState } from 'react';
import { toGetData, toGetStat } from '../Utils/API';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Accueil = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [clubs, setClubs] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    toGetData().then((res) => {
      if (res) {
        setUsers(res.users);
        setClubs(res.clubs);
      }
    });
    toGetStat().then((res) => {
      setData(res);
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  //     const data = [
  //   {
  //     name: 'Page A',
  //     uv: 4000,
  //     pv: 2400,
  //     amt: 2400,
  //   },
  //   {
  //     name: 'Page B',
  //     uv: 3000,
  //     pv: 1398,
  //     amt: 2210,
  //   },
  //   {
  //     name: 'Page C',
  //     uv: 2000,
  //     pv: 9800,
  //     amt: 2290,
  //   },
  //   {
  //     name: 'Page D',
  //     uv: 2780,
  //     pv: 3908,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'Page E',
  //     uv: 1890,
  //     pv: 4800,
  //     amt: 2181,
  //   },
  //   {
  //     name: 'Page F',
  //     uv: 2390,
  //     pv: 3800,
  //     amt: 2500,
  //   },
  //   {
  //     name: 'Page G',
  //     uv: 3490,
  //     pv: 4300,
  //     amt: 2100,
  //   },
  // ];

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const formatter = new Intl.DateTimeFormat('fr', { month: 'short' });
    const month = formatter.format(new Date(2023, payload.value - 1, 1));

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {month}
        </text>
      </g>
    );
  };

  const renderLegend = () => {
    return <div style={{ marginTop: '40px', textAlign: 'center' }}></div>;
  };

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const formatter = new Intl.DateTimeFormat('fr', { month: 'long' });
      const month = formatter.format(new Date(2023, label - 1, 1));
      return (
        <div className="custom-tooltip">
          <p className="jin--subtitle-layout" style={{ fontSize: '14px' }}>
            <Row>
              <Col span="12">Nombre:</Col>
              <Col span="12">
                <span style={{ fontSize: '18px' }} className="jin--title-layout">
                  {payload[0].value}
                </span>
              </Col>
            </Row>
          </p>

          <p className="jin--subtitle-layout" style={{ fontSize: '14px' }}>
            <Row>
              <Col span="12">Mois:</Col>
              <Col span="12">
                <span style={{ fontSize: '18px' }} className="jin--title-layout">
                  {month}
                </span>
              </Col>
            </Row>
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <Row style={{ paddingTop: '20px' }} gutter={0}>
            <Col span="1" />

            <Col xl={6} md={10}>
              <Card className="jin--card-accueil" title="Nombre d'enseignants" bordered={true}>
                <span className="jin--card-nb"> {users.filter((el) => el.type == 1).length}</span>
                <br />
                <span className="jin--card-subelement"> Enseignant(s)</span>
              </Card>
            </Col>
            <Col span="1" />

            <Col xl={6} md={10}>
              <Card className="jin--card-accueil" title="Nombre d'adhérants" bordered={true}>
                <span className="jin--card-nb"> {users.filter((el) => el.type == 2).length}</span>
                <br />
                <span className="jin--card-subelement"> Adhérant(s)</span>
              </Card>
            </Col>
            <Col span="1" />

            <Col xl={6} md={10}>
              <Card className="jin--card-accueil" title="Nombre de clubs" bordered={true}>
                <span className="jin--card-nb"> {clubs.length}</span>
                <br />
                <span className="jin--card-subelement"> Club(s)</span>
              </Card>
            </Col>
            <Col span="1" />
          </Row>
          <Row style={{ marginTop: '8vh' }} gutter={0}>
            <Col span="1" />

            <Col xl={6} md={10}>
              <Card className="jin--card-accueil" title="Nombre d'enseignants inactif(s)" bordered={true}>
                <span className="jin--card-nb">
                  {' '}
                  {users.filter((el) => el.type == 1 && el.password == null).length}
                </span>
                <br />
                <span className="jin--card-subelement"> Enseignant(s)</span>
              </Card>
            </Col>
            <Col span="1" />

            <Col xl={6} md={10}>
              <Card className="jin--card-accueil" title="Meilleur club en terme de nb d'adhérants" bordered={true}>
                <span className="jin--card-nb">
                  {' '}
                  {clubs.length > 0 ? Math.max(...clubs.map((o) => o.students.length)) : 0}
                </span>
                <br />
                <span className="jin--card-subelement"> Adhérant(s)</span>
              </Card>
            </Col>
            {/* <Col span="1"/>

    <Col xl={6} md={10}>
      <Card className="jin--card-accueil" title="Meilleur enseignant en terme de nb de contenu" bordered={true}>
        <span className="jin--card-nb"> 33</span>
        <br/>
        <span className="jin--card-subelement"> Club(s)</span>
      </Card>
    </Col>
        <Col span="1"/> */}
          </Row>

          <Row gutter={0} style={{ paddingBottom: '4vh' }}>
            <Col span="1" />
            <Col xl={10} md={20} style={{ marginTop: '4vh' }}>
              <Card className="jin--card-accueil" title="L'évolution du nombre d'adhérants par mois" bordered={true}>
                <BarChart
                  width={500}
                  height={400}
                  data={data}
                  margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="month" tick={<CustomizedAxisTick />} />
                  <YAxis type="number" domain={[0, 'dataMax + 10']} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="valueStudent" fill="#ED6A2F" />
                  <Legend content={renderLegend} />
                </BarChart>
              </Card>
            </Col>
            <Col xl={1} />

            <Col xl={10} md={20} style={{ marginTop: '4vh' }}>
              <Card className="jin--card-accueil" title="L'évolution du nombre d'enseignants par mois" bordered={true}>
                <BarChart
                  width={500}
                  height={400}
                  data={data}
                  margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="month" tick={<CustomizedAxisTick />} />
                  <YAxis type="number" domain={[0, 'dataMax + 10']} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="valueTeacher" fill="#ED6A2F" />
                  <Legend content={renderLegend} />
                </BarChart>
              </Card>
            </Col>
            <Col span="1"></Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Accueil;
