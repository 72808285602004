import { Layout, Alert, Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { toLogin, toUpdatePassword } from '../Utils/API';
import { errorMsg, successMsg } from '../Utils/Utils';
import cookie from 'react-cookies';
import { useParams } from 'react-router-dom';
import ReactPasswordChecklist from 'react-password-checklist';

const UpdatePassword = () => {
  const { key } = useParams<string>();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [captchaActive, setCaptchaActive] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordAgain, setPasswordAgain] = useState<string>('');
  useEffect(() => {
    cookie.remove('token', { path: '/' });
  }, []);
  const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };

  const submit = () => {
    toUpdatePassword(password, key!).then((res) => {
      switch (res.type) {
        case -1:
          errorMsg('Erreur', 'Clé non trouvée');
          break;
        case 0:
          errorMsg('Erreur', 'Votre lien est expirée, merci de demander un autre depuis votre administrateur');
          break;
        case 1:
          successMsg('Succées', 'Votre mot de passe a été mis à jours avec succés');
          navigate('/login');
          break;

        default:
          break;
      }
    });
  };

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
      <Layout.Content
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Form
          className="jin--form-login"
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
            username: '',
            password: '',
          }}
          form={form}
          onFinish={submit}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={require('../Styles/images/logo.svg').default} alt="j'innove" />
          </div>

          <div
            style={{
              marginTop: '3vh',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Form.Item
              label=""
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Veuillez taper votre mot de passe!',
                },
              ]}
            >
              <Input.Password
                onChange={(e) => setPassword(e.target.value)}
                className="jin--input"
                placeholder="Mot de passe"
              />
            </Form.Item>

            <Form.Item
              label=""
              name="password2"
              rules={[
                {
                  required: true,
                  message: 'Veuillez retaper votre mot de passe!',
                },
              ]}
            >
              <Input.Password
                onChange={(e) => setPasswordAgain(e.target.value)}
                className="jin--input"
                placeholder="Retapez votre mot de passe"
              />
            </Form.Item>
            <Form.Item className="jin--form-item-login">
              <ReCAPTCHA
                sitekey="6Lf_XA0oAAAAAMUump3Cf4Mmz6dxO-amQQledY5-"
                onChange={(c) => (c ? setCaptchaActive(true) : setCaptchaActive(false))}
              />
            </Form.Item>
            <ReactPasswordChecklist
              rules={['minLength', 'number', 'capital', 'match']}
              minLength={6}
              value={password}
              className="jin--checklist-text"
              valueAgain={passwordAgain}
              onChange={(isValid) => {}}
              messages={{
                minLength: 'Le mot de passe comporte au moins 6 caractères.',
                number: 'Le mot de passe comporte un numéro.',
                capital: 'Le mot de passe a une lettre majuscule.',
                match: 'Mots de passe identitiques.',
              }}
            />

            <Form.Item className="jin--form-item-login">
              <Button
                className="jin--btn  jin--btn-primary"
                htmlType="submit"
                disabled={password !== passwordAgain || !captchaActive}
              >
                Valider
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Layout.Content>
      <Layout.Footer
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span className="jin--text">By Fidel Metouia</span>
      </Layout.Footer>
    </Layout>
  );
};

export default UpdatePassword;
