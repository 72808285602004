import { Card, Col, Row } from 'antd';

const About = () => {
  return (
    <>
      <Row style={{ paddingTop: '20px' }} gutter={0}>
        <Col span="1" />

        <Col xl={10} md={10}>
          <Card
            className="jin--card-accueil"
            title={
              <span className="jin--card-nb" style={{ fontSize: '3vh' }}>
                {' '}
                Fidel
              </span>
            }
            bordered={true}
          >
            <div style={{ textAlign: 'center' }}>
              <img className="jin--img-apropos" src={require('../Styles/images/fidel.jpg')} alt="" />
            </div>
            <p className="jin--apropos-text">
              Fondée en 2013, FIDEL est une association à but non lucratif qui a pour mission la promotion du
              développement durable de la délégation de Metouia.
            </p>
            <ul className="jin--apropos-text">
              {' '}
              Elle a pour objectif, entre autres :<li>Promotion de l’entrepreneuriat jeunesse.</li>
              <li>
                {' '}
                Le renforcement des capacités des jeunes et des différentes composantes de la société civile et les
                collectivités territoriales ;{' '}
              </li>
              <li>Appui aux efforts de l'Etat dans le développement économique, social et culturel ; </li>
              <li>Appui technique aux différents acteurs locaux en matière de développement local et durable ;</li>
              <li>
                {' '}
                La promotion et l’impulsion de synergies entre les interventions et actions des différents acteurs
                (autorités publiques, collectivités territoriales, société civile, secteur privé.);{' '}
              </li>
            </ul>
          </Card>
        </Col>
        <Col span="1" />

        <Col xl={10} md={10}>
          <Card
            className="jin--card-accueil"
            title={
              <span className="jin--card-nb" style={{ fontSize: '3vh' }}>
                {' '}
                INNOV'I
              </span>
            }
            bordered={true}
          >
            <div style={{ textAlign: 'center' }}>
              <img className="jin--img-apropos" src={require('../Styles/images/innovi.png')} alt="" />
            </div>
            <p className="jin--apropos-text">
              Innov’i – EU4Innovation est un projet d’appui à l’écosystème de l’entrepreneuriat et de l’innovation en
              Tunisie, financé par l’Union européenne et mis en œuvre par Expertise France. Ce projet vise à accompagner
              le renforcement, la structuration et la pérennisation de l’écosystème de l’entrepreneuriat et de
              l’innovation tunisienne.
            </p>
          </Card>
          <Card
            className="jin--card-accueil"
            style={{ marginTop: '10px' }}
            title={
              <span className="jin--card-nb" style={{ fontSize: '3vh' }}>
                {' '}
                Expertise France
              </span>
            }
            bordered={true}
          >
            <div style={{ textAlign: 'center' }}>
              <img className="jin--img-apropos" src={require('../Styles/images/expertise.png')} alt="" />
            </div>
            <p className="jin--apropos-text">
              Agence publique, est un acteur clé de la coopération technique internationale.
            </p>
            <p className="jin--apropos-text">
              Elle conçoit et met en œuvre des projets qui renforcent durablement les politiques publiques dans les pays
              en développement et émergents. Gouvernance, sécurité, climat, santé, éducation.
            </p>
            <p className="jin--apropos-text">
              {' '}
              Elle intervient sur des domaines clés du développement durable et contribue aux côtés de ses partenaires à
              la concrétisation de l’Agenda 2030.
            </p>
          </Card>
        </Col>
      </Row>

      <Row style={{ paddingTop: '20px' }} gutter={0}>
        <Col span="1" />

        <Col xl={10} md={10}></Col>
      </Row>
    </>
  );
};

export default About;
