import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LayoutMenu from './layouts/LayoutMenu';
import './Styles/App.scss';
import Login from './pages/Login';
import UpdatePassword from './pages/UpdatePassword';
import NotResponsive from './components/NotResponsive';

function App() {

function handleWindowSizeChange() {
    if(window.innerWidth <= 768)
    {
      setIsMobile(true);
    }else{
      setIsMobile(false);
    }
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);
  const [isMobile, setIsMobile] = useState(false)
  return (
    <BrowserRouter>
      {!isMobile ?
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<LayoutMenu />} />
        <Route path="/update_password/:key" element={<UpdatePassword />} />
      </Routes> :       <NotResponsive/>
}
    </BrowserRouter>
  );
}

export default App;
