import { Layout, Alert, Button, Form, Input, Row, Col, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { toLogin, toResendActivationLinkEmail } from '../Utils/API';
import { errorMsg, successMsg } from '../Utils/Utils';
import cookie from 'react-cookies';

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formEmail] = Form.useForm();
  const [loginError, setLoginError] = useState<string>('');
  const [captchaActive, setCaptchaActive] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [forgotOpen, setForgotOpen] = useState<boolean>(false);

  const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    if (cookie.load('token')) {
      navigate('/');
    }
  }, [navigate]);

  const submit = () => {
    form
      .validateFields()
      .then((values) => {
        toLogin(values.username, values.password).then((res) => {
          switch (res) {
            case 1:
              successMsg('Connexion effectuée avec succès!', `Bienvenue ${cookie.load('nomComplet')}`);
              navigate('/');
              break;
            case 0:
              errorMsg('Erreur de connexion!', 'veuillez contacter votre administrateur!');
              setLoginError('Votre compte a été désactivé, veuillez contacter votre administrateur');
              break;

            default:
              errorMsg('Erreur de connexion!', 'veuillez contacter votre administrateur!');
              setLoginError('Erreur de connexion, veuillez contacter votre administrateur');
              break;
          }
        });
      })
      .catch((err) => {
        setLoginError(err);
      });
  };

  const sendActivationEmail = () => {
    formEmail.validateFields().then((values) => {
      console.log(values);
      toResendActivationLinkEmail(values.email).then((res) => {
        successMsg('Informations', 'Un email vous a été envoyé');
        setForgotOpen(false);
      });
    });
  };

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
      <Layout.Content
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Form
          className="jin--form-login"
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
            username: '',
            password: '',
          }}
          form={form}
          onInput={() =>
            form
              .validateFields()
              .then(() => setDisabled(false))
              .catch(() => setDisabled(true))
          }
          onFinish={submit}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={require('../Styles/images/logo.svg').default} alt="j'innove" />
          </div>

          <div
            style={{
              marginTop: '3vh',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Form.Item
              label=""
              name="username"
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir votre nom d'utilisateur!",
                },
              ]}
            >
              <Input className="jin--input" placeholder="Login" />
            </Form.Item>

            <Form.Item
              label=""
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre mot de passe!',
                },
              ]}
            >
              <Input.Password className="jin--input" placeholder="Mot de passe" />
            </Form.Item>
            <Form.Item className="jin--form-item-login">
              <ReCAPTCHA
                sitekey="6Lf_XA0oAAAAAMUump3Cf4Mmz6dxO-amQQledY5-"
                onChange={(c) => (c ? setCaptchaActive(true) : setCaptchaActive(false))}
              />
            </Form.Item>
            <Form.Item className="jin--form-item-login">
              {loginError !== '' && <Alert message={loginError} type="error" style={{ marginBottom: 20 }} />}
            </Form.Item>

            <Form.Item className="jin--form-item-login">
              <Button className="jin--btn  jin--btn-primary" htmlType="submit" disabled={disabled && !captchaActive}>
                Se connecter
              </Button>
            </Form.Item>
            <div style={{ textAlign: 'center', marginTop: 10 }}>
              <a onClick={() => setForgotOpen(true)}>Mot de passe oublié</a>
            </div>
          </div>
        </Form>
      </Layout.Content>
      <Modal
        className="jin--modal"
        onCancel={() => setForgotOpen(false)}
        open={forgotOpen}
        style={{ minWidth: '90vh', top: 20 }}
        title={'Mot de passe oublié'}
        footer={[]}
      >
        <Form form={formEmail} style={{ marginTop: '40px' }} onFinish={sendActivationEmail}>
          <Form.Item
            label=""
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Veuillez vérifier votre email!',
              },
            ]}
          >
            <Input className="jin--input" placeholder="Email" />
          </Form.Item>
          <Row justify={'center'}>
            <Button htmlType="submit" className="jin--btn jin--btn-primary jin--btn-custom-header">
              Envoyer l'email d'activation
            </Button>
          </Row>
        </Form>
      </Modal>
      <Layout.Footer>
        <Row>
          <Col span="8">
            <span className="jin--text">By Fidel Metouia</span>
          </Col>
          <Col span="8">
            <span className="jin--text">
              <span style={{ color: '#6B6B6B' }}>Email: </span>
              <a href="mailto:fidelmetouia@gmail.com">
                {' '}
                <span className="jin--text">fidelmetouia@gmail.com</span>
              </a>
            </span>
          </Col>
          <Col span="8">
            <span className="jin--text">
              <span style={{ color: '#6B6B6B' }}>Téléphone:</span> 93 89 81 95
            </span>
          </Col>
        </Row>
      </Layout.Footer>
    </Layout>
  );
};

export default Login;
