import { Form, Layout } from "antd";

const NotResponsive = () =>{
    return <Layout style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
      <Layout.Content
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex:1000,
        }}
      >
          
          
          
            <Form
          className="jin--form-login"
          >
            <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={require('../Styles/images/logo.svg').default} alt="j'innove" />
          </div>
          <div
            style={{
              marginTop: '3vh',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
          
            <Form.Item style={{textAlign: 'center'}}>
                        <img src={require('../Styles/images/warning.png')} alt="j'innove" />

            </Form.Item>
            <Form.Item style={{textAlign: 'center'}}>
                <p className="jin--title-layout">Informations</p>
                <p >Le site n'est pas disponible en version<br/> mobile pour le moment. </p>
                    <p>Nous nous excusons pour tout désagrément.<br/> Veuillez l'accéder depuis un ordinateur ou une tablette pour une expérience optimale.</p>
            </Form.Item>
            </div>
          </Form>
          

      </Layout.Content>
      <Layout.Footer
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span className="jin--text">By Fidel Metouia</span>
      </Layout.Footer>
    </Layout>
}

export default NotResponsive;